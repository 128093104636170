function isImageReady(img) {
    if (!img.complete) {
        return false;
    }

    if (img.naturalWidth === 0) {
        return false;
    }

    return true;
}

$(function() {
	
    // Hero animation / loader helper
    var heroNew = $('.hero-new');
    heroNew.each(function () {
        var $this = $(this);
        var heroImg = $this.find('.hero-new__image img');
        var heroImgSrc = heroImg.attr('src');
        
        heroImg
            .attr('src', '')
            .attr('src', heroImgSrc)
            .on('load', function() {
                setTimeout(function() {
                    $this.addClass('js-hero-new--ready');
                    $(document).trigger($.Event('hero.ready'));
                }, 200);
                setTimeout(function() {
                    // $this.find('.bar-loader').remove();
                }, 2500);
            });
    });
});


// ----------------------------------------------------------------------------
// Setup some basic reusable gsap inits
// ----------------------------------------------------------------------------

parallaxBG('body', '.hero-new__image picture', 80, false);
// parallaxBG('body', '.hero-new__body', 50);

// Basic parallax effect
function parallaxBG(parent, child, percent, allSizes) {
	let parallaxBackgroundParent = document.querySelectorAll(parent);
	
    if (allSizes === true) {
        parallaxBackgroundParent.forEach((item) => {
            let parallaxBackgroundChildren = item.querySelectorAll(child);
            parallaxBackgroundChildren.forEach((itemInner, index) => {
                if (index === 0) {
                    gsap.to(itemInner, {
                        yPercent: percent,
                        ease: "none",
                        scrollTrigger: {
                            trigger: item,
                            start: "top top",
                            end: "bottom top",
                            scrub: true,
                        },
                    });
                }
            });
        })
    } else {
        ScrollTrigger.matchMedia({
            "(min-width: 992px)": function() {
                parallaxBackgroundParent.forEach((item) => {
                    let parallaxBackgroundChildren = item.querySelectorAll(child);
                    parallaxBackgroundChildren.forEach((itemInner, index) => {
                        if (index === 0) {
                            gsap.to(itemInner, {
                                yPercent: percent,
                                ease: "none",
                                scrollTrigger: {
                                    trigger: item,
                                    start: "top top",
                                    end: "bottom top",
                                    scrub: true,
                                },
                            });
                        }
                    });
                });
            }
        });
    }
}